

function Faq(){
    return(
        <div>
            FAQs
        </div>
    );
}

export default Faq;